//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    Header: () => import("@/components/Header"),
    MyAttendance: () => import("./my-attendance"),
    MemberAttendance: () => import("./member-attendance"),
  },
  data() {
    return {
      tabType: 1,
      tabList: [
        {
          value: 1,
          label: "我的考勤",
        },
        {
          value: 2,
          label: "成员考勤",
        },
      ],
    };
  },
  methods: {
    /**
     * tab视图切换
     */
    tabChange(val) {
      this.tabType = val;
    },
  },
};
